import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from "react-helmet";
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

function PolicyPage() {
  return (
    <div className="privacy-big-wrapper">
      <Helmet htmlAttributes={{lang: 'sv'}}>
        <meta charSet="utf-8" />
        <title>Integritet och användarvillkor</title>
        <meta name="description" content="Integritet och användarvillkor för Familjens kalender. Läsa vilka vilkor som finns för att använda kalendertjänsten." />
      </Helmet>
      <div className="page-default-grid-layout">
        <header className="app-header">
          <div className="app-header-aside-left">
          </div>
          <div className="app-header-center">
            <h1>Familjens kalender</h1> 
            <h2>Skapa en egen personlig kalender</h2>
            <h3>Dela din personliga kalender med familj och vänner som PDF eller som utskrift</h3>
            <button className="app-header-button-click-me"><Link to="/kalender">Designa din kalender</Link></button>
            </div>  
          <div className="app-header-aside-right">
          </div>
        </header>   
        <nav className="app-nav">
          <Navigation />
        </nav>

        <main className="page-default-main">
          <h1>Integritet och användarvillkor</h1>
          <br/>
          <h2>Integritetspolicy</h2>
          <p>
            <i>Familjens kalender</i> är en webbapplikation med syftet att göra det lättare att skapa en egen personlig kalender.
             För att kunna göra kalendern personlig är det också viktigt att du tryggt kan våga använda
            egna bilder och information som namn och födelsedagar. 
          </p>
          <br/>
          <p>
            För att uppfylla GDPRs krav på datahantering så sparas ingen personlig data idag.
            All information som du bidrar med finns bara lokalt i din webbläsare.
            Målet är också att minimera den information som efterfrågas,
            för födelsedagar behöver du inte lägga in mer än vad som krävs för att namnet ska dyka upp på rätt datum,
            det vill säga månad och dag. 
          </p>
          <br/>

          <h3>Hur dina bilder hanteras</h3>
          <p>
            Det är viktigt att dina personliga bilder kan användas tryggt.
            Inga bilder som du använder skickas iväg eller sparas av applikationen på någon server,
            utan finns bara lokalt i din webbläsare på din dator medan du arbetar med din kalender.
          </p> 
          <br/>
          <h3>Hur din information hanteras</h3>
          <p>
            För dina inställningar, som födelsedagar och namnsdagar du lägger till,
            så sparas inte heller detta av applikationen på någon server.
            Det finns bara lokalt i din webbläsare.
            Du har dock möjligheten att spara dessa till en fil på din dator.
            Din webbläsare kan dock spara information du skrivit in,
            t.ex. namnsdagar och födelsedagar så att dessa sedan kan ges som automatiska förslag (autofyll).
            Denna webbinformation borde du kunna rensa i din webbläsare vid behov.
          </p>
          <br/>
          <h3>Hur statistik används</h3>
          <p>
            Idag används ingen statistik (detta kan dock ändras i framtiden). 
          </p>  
          <br/>
          <h3>Hur cookies (kakor) används</h3>
          <p>
            Idag används inga cookies (detta kan dock ändras i framtiden). 
          </p>  
          <br/>
          <h2>Användarvillkor</h2>
          <p>
            Om du väljer att använda <i>Familjens kalender</i> till att skapa en egen personlig <Link to="/kalender">kalender</Link> så
            accepterar du att kalender kommer i befintligt skick och med eventuella fel.
            Du har inte rätt till skadestånd på grund av felaktig information i kalendern eller bristande kvalité. 
          </p>  
          <br/>
          <p>
            Du får använda tjänsten <i>Familjens kalender</i> till att skapa en eller flera kalendrar gratis, utan kostnad,
             för personligt bruk eller som gåva till andra.
            Du får inte använda tjänsten för kommersiellt bruk utan tillstånd.    
          </p>  
          <br/>
          <p>
            De bilder du använder ansvar du för att du har rätt att använda. 
            Du har inte rätt att kopiera några av bilderna eller koden på Familjens kalender. Dessa skyddas av copyright. 
          </p>  
          <br/>
          <h2>Ändringar av villkor</h2>
          <p>
            Notera att villkoren här kan ändras, till exempel när det kommer till användningen av cookies och statistik.
            Dina bilder och den information du lägger in för att skapa kalendern kommer dock inte sparas utan ditt samtycke
            (t.ex. vid en potentiell framtida login-funktion för att spara dina kalendrar).
          </p>  
          <br/>

          <p>
            <strong>Kontakta info@familjenskalender.se vid eventuella frågor.</strong>  
          </p>  
          <br/>
        </main>
        <Footer/>
      </div>
    </div>
  )
}

export default PolicyPage;